html {
  height: 100%;
  touch-action: manipulation;
  -webkit-overflow-scrolling: touch;
}

body {
  position: relative;
  min-width: 320px;
  height: 100%;
  background-color: $main-background;

}

// Comment out if bootstrap is enabled
.container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 10px;
  .main-container{
    padding: 10px 91px;

  }
}

.content {
  margin-top: 70px;
}


.js-open-modal {
  &:hover {
    cursor: pointer;
  }
}

.select-content.active-select{
  display: block;
  position: absolute;
  width: max-content;
  background-color: #fafafa;
}
.select-content{
  .select-option:hover{
    background-color: $blue-gray;
  }
  label:hover{
    background-color: $blue-gray;
  }
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #67AECA;
  border-color: #67AECA;
}