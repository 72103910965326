.photo-module {
    &-inner {
        display: flex;
        align-items: start;
        gap: 4%;
        padding-bottom: 20px;
        flex-wrap: wrap;
        box-shadow: none;
    }

    .empty-image {
        width: 142px;
        height: 142px;
    }

    .photo-title {
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .photo-descr {
        font-size: 16px;
        margin-bottom: 15px;
        color: #B1B1B1;
    }

    .img-logo {
        display: block;
        width: 142px;
        height: 142px;
    }
}

.preview-images {
    margin-top: 20px;
    width: 100%;

    .list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        user-select: none;
    }


}

.preview-img-card {
    position: relative;
    flex-shrink: 0;
    display: flex;
    margin: 8px1;
    cursor: grab;
    user-select: none;
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2);
    border-radius: "100%";

    .prewiew-img {
        display: block;
        width: 134px;
        height: 134px;
        object-fit: cover;
        position: relative;
        pointer-events: none;
    }

    .icons {
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        z-index: 5;
    }

    .icon-bg {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(1, 5, 22, 0.5);
        color: #F5F5F5;

        &.grab {
            cursor: grab;

        }

        &.click {
            cursor: pointer;
        }
    }

    .firsr-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid #F5F5F5;
    }
}

.drop-zone {
    padding: 10px;
    width: 470px;

    &:-webkit-drag {
        background-color: #B1B1B1;
        cursor: grab;
    }

}

.drop-zone.drag-over {
    border: 2px dashed #67AECA;
}