.personal-data {

    &-title {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 600;
    }
    .languges-label{
        display: block;
        margin-bottom: 10px;
    }
}
// .input-checkbox-wrapper {
//     display: flex;
//     gap: 20px;
//     margin-bottom: 20px;
//     .label{
//         width: 100%;
//     }
// }