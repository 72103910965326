.upload {
    &-label {
        text-decoration: underline;
        font-weight: 700;
    }

    &-text-wrapper {
        display: flex;
        align-items: center;
        width: max-content;
        justify-content: center;
        gap: 10px;
        padding: 7px 15px;
        border: 1px solid #237FA4;
        color: #237FA4;
        &:hover {
            cursor: pointer;
        }
    }
}