.checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  max-width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  user-select: none;
  cursor: pointer;

  input {
    @include visuallyHidden;

    &:checked ~ .checkbox__box::before {
      display: block;
    }
  }

  &__box {
    position: relative;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #ddd;

    &::before {
      content: '';
      position: absolute;
      left: 3px;
      top: 0;
      transform: rotate(45deg);
      width: 7px;
      height: 12px;
      border-bottom: 2px solid #333;
      border-right: 2px solid #333;
      display: none;
    }
  }

  &__title {
    min-width: 1px;
  }
}