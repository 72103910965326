.switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 20px;
    &-comp{
        margin-bottom: 24px;
    }
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &-inner {
        display: flex;
        align-items: start;
        gap: 10px;
    }

    &-text {
        max-width: calc(100% - 70px);
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px solid #D1D1DD;
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 2px;
        bottom: 2.8px;
        background-color: #D1D1DD;
        -webkit-transition: .4s;
        transition: .4s;
    }
}

input:checked+.slider {
    background-color: #67AECA;
    border-color: #67AECA;
}

input:focus+.slider {
    box-shadow: 0 0 1px #67AECA;
}

input:checked+.slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    background-color: #fff;
}

.slider.round {
    border-radius: 16px;
}

.slider.round:before {
    border-radius: 50%;
}

.label-text {
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
}
