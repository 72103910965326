.adv-list-card {
  display: flex;
  border-bottom: 1px solid #a0a0a0;

  &:first-child {
    border-top: 1px solid #a0a0a0;

  }

  .list-card-info {
    display: flex;
    position: relative;
    gap: 20px;
    width: 80%;
    border-right: 1px solid #a0a0a0;
    padding: 20px 100px 20px 20px;

    &--inner {
      width: calc(100% - 335px);
    }
  }

  &-tools {
    width: 20%;
    padding: 20px;

    &-status--label {
      color: #a0a0a0;
      margin-bottom: 10px;
    }

    &-status--text {
      width: max-content;
      border-bottom: 1px dashed #a0a0a0;
      padding-bottom: 3px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }
}

.applications-list-card {
  display: flex;
  border-bottom: 1px solid #a0a0a0;

  &:first-child {
    border-top: 1px solid #a0a0a0;

  }

  .list-card-info {
    display: flex;
    position: relative;
    gap: 20px;
    width: 50%;
    border-right: 1px solid #a0a0a0;
    padding: 20px;

    &--inner {
      width: calc(100% - 335px);
    }
  }
}

.favorites-list-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 32px;
  border-bottom: 1px solid #a0a0a0;

  &:first-child {
    border-top: 1px solid #a0a0a0;

  }
  .delete-button-wrapper{
    position: absolute;
    right: 30px;
    top: 30px;
    &:hover{
      cursor: pointer;
    }
  }
  .list-card-info {
    display: flex;
    position: relative;
    gap: 20px;
    width: 80%;
    padding: 20px;

    &--inner {
      width: calc(100% - 335px);
    }
  }
}