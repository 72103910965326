body {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  overflow-wrap: break-word;
  color: $text-color;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  // margin-bottom: 0.5em;
  font-weight: 500;
  line-height: 1.3;
}

.rsw-ce ul {
  list-style: disc;
  padding-left: 2em;
}

.rsw-ce ol {
  list-style: decimal;
  padding-left: 2em;
}

.rsw-ce h1,
.h1 {
  font-weight: 600;
  font-size: 24px;
}

.rsw-ce h2,
.h2 {
  font-weight: 500;
  font-size: 22px;
}

.rsw-ce h3,
.h3 {
  font-size: 20px;
}

.rsw-ce h4,
.h4 {
  font-size: 22px;
}

.rsw-ce h5,
.h5 {
  font-size: 18px;
}

.rsw-ce h6,
.h6 {
  font-size: 16px;
}

h1,
.h1 {
  font-size: 40px;
}

h2,
.h2 {
  font-size: 34px;
}

h3,
.h3 {
  font-size: 28px;
}

h4,
.h4 {
  font-size: 22px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

a {
  color: $link-color;
  text-decoration: none;

  &[href^='tel'],
  &[href^='mailto'] {
    white-space: nowrap;
  }
}

ol,
ul {
  list-style: none;
}

.error_msg {
  font-size: 14px;
  color: red;
}

.title {
  margin-bottom: 20px;
}

.ql-editor{
  min-height: 250px;
}
