.ant-btn-default {
  border: none;
}

.ant-dropdown-menu {
  border-radius: 0;
}

// .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
//   background-color: #E9F9FF !important;
// }

.ant-space {
  min-height: 40px;
}

form {
  .ant-space {
    display: flex;
    height: 44.8px !important;
    box-sizing: border-box !important;
  }
}

.ant-space-item {
  width: 100%;
  height: 100%;
}

// .ant-select {
//   height: 44px;
// }
.ant-select-arrow {
  color: #000 !important;
}

.ant-select-selector,
.ant-select-single {
  height: 100% !important;
  border-radius: 0 !important;
}

.ant-select-selector {
  border: 2px solid #F5F5F5 !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #F5F5F5 !important;
  box-shadow: none !important;
}

.ant-modal {
  .applications-list-card {
    border-bottom: none !important;
  }
}

.anticon {
  svg {
    width: 10px;
    height: 10px;
  }
}

.ant-select-dropdown {
  border-radius: 0;
}

// .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   background-color: #67AECA;
// }

// .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
//   background-color: #E9F9FF !important;
// }