.main {
  margin-bottom: 20px;
  position: relative;
  &-inner {
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }

  &-title {
    font-size: 36px;
    font-weight: 600;
    padding-bottom: 16px;
    margin-bottom: 8px;
    border-bottom: 3px solid #fff;
  }

  &-title-wrapper {
    padding: 60px 0 32px 0;
    margin-bottom: 10px;
  }

  &-breadcrumbs {
    font-size: 14px;
    a {
      color: #B1B1B1;
    }
  }
}