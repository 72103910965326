.inputs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .label {
        width: 47%;
        position: relative;
    }

    .input-wrapper {
        width: 100%;
    }
}

.input-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 2px solid #F5F5F5;
    padding: 0 10px;

    .fa {

        &-user,
        &-mobile-screen-button,
        &-envelope {
            color: #CCCCCC;
        }
    }

    input {
        padding: 10px;
        border: none;
        width: 100%;
    }
}

.input-image {
    width: 20px;
    height: 20px;
    text-align: center;
}

.fa-trash {
    margin-left: -20px;
    color: #CCCCCC;

    &:hover {
        cursor: pointer;
        color: red;
    }
}

.check-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 50px;

    .input-wrapper {
        width: 47%;
    }
}

.languges-label {
    font-size: 16px;
    margin-bottom: 20px;
}

.label-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 10px;
}

.label-text {
    font-size: 16px;
    line-height: 30px;
}

.number-input-with-select {
    &-inner {
        // position: relative;
        display: flex;
        align-items: flex-end;
        width: 47%;

        .label {
            width: 80%;
        }
    }
    &--select-wrapper{
        width: 20%;
    }
    .input-wrapper {
        border-right: none;
    }
}

.number-input-with-unit {
    width: 23%;
    position: relative;

    .label {
        width: 100%;
    }
}

.unit {
    position: absolute;
}

.unit-frames {
    display: flex;

}

.unit-frame {
    border: 1px solid #67AECA;
    width: 40px;
    height: 30px;
    color: #67AECA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3px;

    &:hover {
        cursor: pointer;
    }

    .unit-text {
        position: relative;
    }

    .area {
        position: absolute;
        font-size: 10px;
    }
}

.unit-frame.active {
    color: #fff;
    background-color: #67AECA;
}

.text-area {
    width: 100%;
    min-height: 300px;

    &-wrapper {
        width: 100%;
    }
}


.rsw-ce {
    min-height: 300px !important;
}

.password-toggle:hover {
    cursor: pointer;
}

.inputs-inner {
    display: flex;

    .label {
        width: 50%;
    }
}