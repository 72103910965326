.defaults-inputs-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
}
.form-field{
    width: 47%;
    // &-label{
    //     display: flex;
    //     align-items: center;
    //     gap: 10px;
    //     margin-bottom: 10px;
    // }
}
.safety-field{
    margin-bottom: 42px;
}
.label-icon{
    display: block;
    width: 28px;
    height: 20px;
}
.settings-title{
    margin-bottom: 24px;
}