* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
form,
section,
summary {
  display: block;
}

[hidden],
template {
  display: none;
}

button {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  // -webkit-appearance: none;
  cursor: pointer;
}

button,
[role="button"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
  // -webkit-appearance: none;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

textarea {
  overflow: auto;
  resize: vertical;
  // resize: none;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@-ms-viewport {
  width: device-width;
}

table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
}

strong,
b {
  font-weight: 700;
}

i,
em {
  font-style: italic;
}

small {
  font-size: 80%;
}

sup,
sub {
  position: relative;
  vertical-align: baseline;
  font-size: 65%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a:hover {
  cursor: pointer;
}

img,
video,
svg {
  max-width: 100%;
}

img {
  height: auto;
}

iframe {
  border: none;
}

::-webkit-scrollbar {
  width: 2px;
  background: #d9d9d9;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  height: 40px;
  z-index: 9;
  background: $blue;
}

::-webkit-scrollbar-button {
  display: none;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;
  border-radius: 0;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    height: 3px;
  }

  &::-moz-range-track {
    background: #E1E9EC;
    height: 3px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -5px;
    width: 12px;
    height: 12px;
    border-radius: 0;
    background-color: #67AECA;
  }

  &:active::-webkit-slider-thumb {
    background-color: #67AECA;
  }

  &::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 0;
    margin-top: -5px;
    background-color: #67AECA
  }

  &::-webkit-progress-bar {
    background-color: #67AECA;
  }

  &::-moz-range-progress {
    background-color: #67AECA;
  }
}



.radio-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  display: block;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px solid #D1D1DD;
}

.radio-container:hover input~.checkmark:hover {
  border: 2px solid $light-blue;
}

.radio-container input:checked~.checkmark {
  background-color: $light-blue;
}

.checkmark:after {
  content: "";
  display: none;
}

.radio-container input:checked~.checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  position: absolute;
  left: 30px;
  top: 16px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#phone {
  width: 250px;
}

.iti {
  width: 100%;
}

.iti__flag {
  // background-image: url("../../../public/images/icons/flags/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .iti__flag {
    // background-image: url("../../../public/images/icons/flags/flags.png");
  }
}

.iti__country-list {
  max-width: 400px;

  @include breakpoint(md) {
    font-size: 13px;
    max-width: max-content;
  }
}

.with-error{
  position: relative;
}

.error-wrapper{
  position: absolute;
  top: 100%;
  left: 0;
  &.unit-error{
    top: 140%;
  }
}
