.favorites-delete-button {
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }

    &.active-btn {
        color: #E64C2E;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}