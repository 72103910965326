.profile-content {
    flex: 2;
    padding: 20px;
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px #dedbdb;

    &-inner {
        display: flex;
        align-items: center;
        gap: 18px;
        padding-bottom: 20px;

        .empty-image {
            width: 120px;
            height: 120px;
        }

        .photo-title {
            font-size: 26px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .photo-descr {
            font-size: 16px;
            margin-bottom: 19px;
            color: #B1B1B1;
        }
    }
}