.user-info--sb {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 32px 16px 16px;
    border-bottom: 3px solid #F5F5F5;
    .user-role{
        font-size: 14px;
        margin-bottom: 6px;
    }
    .img-wrapper {
        .img-logo {
            width: 80px;
            height: 80px;
            object-fit: cover;
        }
    }

    &-name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 6px;
    }

    .fa-circle-exclamation {
        color: #B1B1B1;
        margin: 10px 0;
        font-size: 16px;
    }

}

.tooltip {
    position: relative;
    display: inline-block;

    &:hover {
        cursor: pointer;
    }
}

