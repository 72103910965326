.points-btn-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;


    &:hover {
        cursor: pointer;
    }

}

.points-inputs {
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-bottom: 60px;

        .delete-btn {
            position: absolute;
            bottom: -40px;
            left: 0;
        }
        .absolute {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .select-wrapper {
        width: 75%;
    }
    
}