.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  padding: 0.6em 1.5em;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  font-family: inherit;
  text-align: center;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  -webkit-appearance: none;
  user-select: none;
  cursor: pointer;
  transition: color $transition, background-color $transition, border-color $transition;

  &:hover {
    text-decoration: none;
  }

  &--primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;

    &:hover {
      color: #fff;
      background-color: #0b5ed7;
      border-color: #0b5ed7;
    }
  }

  &--secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;

    &:hover {
      background-color: #5c636a;
      border-color: #5c636a;
    }
  }

  &--outline-primary {
    color: #c5a706;
    border: 1px solid #c5a706;

    &:hover {
      color: #fff;
      background-color: #c5a706;
    }
  }

  &--outline-secondary {
    color: #0033ff;
    border: 1px solid #0033ff;

    &:hover {
      color: #fff;
      background-color: #0033ff;
    }
  }

  &--link {
    color: #333;

    &:hover {
      text-decoration: underline;
    }
  }

  &--black {
    padding: 10px 30px;
    background-color: #000;
    color: #fff;
  }
}

.filter-show-btn {
  width: max-content;
  position: relative;
  padding: 10px 30px;
  font-size: 16px;
  color: #237FA4;
  border: 1px solid #237FA4;

  &:hover {
    cursor: pointer;
  }

  .filter-count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #FFD374;
    position: absolute;
    color: #000;
    top:-13px;
    right: -13px;
  }
}

.form-btn {
  display: block;
  padding: 10px 30px;
  border: 1px solid #237FA4;
  color: #237FA4;
}

.adv_list_submit_btn {
  color: #fff;
  background-color: #67AECA;
}