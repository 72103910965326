.favorites-control-panel {
    padding: 20px 0;
    border-top: 1px solid #a0a0a0;

    &:last-child {
        padding-bottom: 0;
        border-bottom: 1px solid #a0a0a0;
    }

    &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 32px;
    }

    &-info {
        display: flex;
        align-items: center;
        gap: 50px;
    }

    .cancel-btn {
        &:hover {
            cursor: pointer;
        }

        &.active-btn:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}