::-webkit-input-placeholder {
  color: #333;
  opacity: 1;
  transition: opacity .15s;
}

:-moz-placeholder {
  color: #333;
  opacity: 1;
  transition: opacity .15s;
}

::-moz-placeholder {
  color: #333;
  opacity: 1;
  transition: opacity .15s;
}

:-ms-input-placeholder {
  color: #333;
  opacity: 1;
  transition: opacity .15s;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  opacity: 0;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  opacity: 0;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
}

.field {
  display: block;
  width: 100%;
  height: 48px;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  box-shadow: none;
  appearance: none;

  &::-ms-clear {
    display: none;
  }

  &--textarea {
    height: 124px;
    padding: 10px;
  }
}