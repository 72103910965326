.general {
    &-inputs-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  &-input-wrapper {
    width: 23%;
    .label{
        width: 100%;
    }
  }
}
.general-label{
  margin-bottom: 11px;
}
