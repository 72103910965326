.delete-btn {
    display: flex;
    align-items: baseline;
    gap: 4px;
    padding: 4px;
    font-weight: 700;
    color: red;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}