.general {
    background-color: #fff;
    padding: 32px 16px;
    margin-bottom: 20px;
    width: 300px;
    box-shadow: 2px 2px 2px 1px #dedbdb;

    &-title {
        margin-bottom: 32px;
    }
}

.editing-lang {
    &-info {
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 16px;
    }

    &-selector-text {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        line-height: 30px;
        margin-bottom: 10px;
    }
}

.save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 0;
    color: #fff;
    width: 100%;
    padding: 20px;
    background-color: #237FA4;

    &:where(.css-dev-only-do-not-override-1uq9j6g).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        color: #fff;
        border-color: none;
        background: #2d98c2;
    }
}

.general-buttons-wrapper{
    display: flex;
    flex-direction: column;
    gap: 15px;
    .upload-text-wrapper{
        width: 100%;
        justify-content: center;
    }
}