.control-panel {
    &-info {
        display: flex;
        align-items: center;
        &-inner {
            display: flex;
            flex: 2;
            gap: 15%;
        }
        &-label{
            font-size: 26px;
            font-weight: 500;
        }
    }
    &-switcher{
        display: flex;
        align-items: center;
        &-btn{
            width: calc(100%/3);
            text-align: center;
            padding: 10px;
            border: 1px solid #B1B1B1;
            color: #B1B1B1;
            &:hover{
                cursor: pointer;
            }
        }
        &-btn.active{
            background-color: #67AECA;
            color: #fff;
            border-color: #67AECA;

        }
    }
}