// Colors
$black: #222;
$green: #029031;
$blue: #01a3dc;
$dark-blue:#237FA4;
$light-blue:#67AECA;
$grey: #f3f3f3;
$light-grey: #f9f9f9;
$gray-light:#F5F5F5;
$blue-gray: #EEF3F4;
$gr:#f4f4f4;
$dg:#8a8a8a;
$orange: #FFD374;

$text-color: $black;
$link-color: $black;
$input-color: $gr;
$placeholder-color:$dg;
$main-background:$gray-light;

// Layout
$gap: 15px;
$container-max-width: 1200px + $gap * 2;

// Breakpoints
$breakpoints: (
  xlg: 1399px,
  lg: 1279px,
  md: 1023px,
  sm: 767px,
  xs: 479px,
  xxs: 379px,
);

// Layers
$header-layer: 5;
$modal-layer: 20;

// Other
$transition-func: cubic-bezier(0.25, 0.1, 0.25, 1);
$transition: 0.3s $transition-func;