.footer {
  background-color: #fff;
  padding: 30px;
  &-inner {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
    text-align: center;
    @include breakpoint(sm){
      flex-direction: column;
      padding: 10px ;
    }
  }
  &-links-wrapper{
    display: flex;
    gap: 20px;
    @include breakpoint(sm){
      gap:10px;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  &-media{
    display: block;
    width: 24px;
    height: 24px;
  }
  li{
    a:hover{
      color: #67AECA;
    }
  }
}