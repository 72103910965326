.form-tag {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    padding: 5px 10px;
    color: #fff;
    background-color: #77B5CF;
    &-text{
        display: flex;
        align-items: center;
        gap: 5px;
        .unit-text{
            position: relative;
        }
        .area{
            position: absolute;
            font-size: 10px;
            right: -6px;
            top: -3px;
        }
    }
    .close {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        &:hover {
            cursor: pointer;
        }
    }
}

.tags-wrapper {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    gap: 20px;
}