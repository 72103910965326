// Layout
.is-hidden {
  display: none !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.hide-sm {

  @include breakpoint(sm) {
    display: none !important;
  }
}

// Typography
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.nowrap {
  white-space: nowrap !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.underline {
  text-decoration: underline !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

// Colors
