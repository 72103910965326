.tooltip .tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    font-size: 16px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 70%;
    left: 50%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}


/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {

    visibility: visible;
    opacity: 1;
}

.tooltip-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 700;
    color: #B1B1B1;
    border-radius: 50%;
    border:2px solid #B1B1B1;

}