.price-module-description {
    background-color: #373937;
    color: #fff;
    padding: 10px 20px;
    position: relative;
    margin-bottom: 20px;
    .close-btn{
        position: absolute;
        top: 8px;
        right: 10px;
        &:hover{
            cursor: pointer;
        }
    }
    
}
.price-input-wrapper{
    display: flex;
}

.price-checkbox{
    margin-top: 20px;
}