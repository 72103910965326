.btn-ouline {
    background-color: #fff;
    color: #67AECA;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 10px 30px;
    border: 1px solid #67AECA;
    box-sizing: border-box;
}