.copy-btn {
    display: flex;
    align-items: baseline;
    gap: 10px;
    color: #237FA4;
    margin-bottom: 15px;

    &-text {
        text-decoration: underline;
    }

    &:hover {
        cursor: pointer;
    }
}