.sidebar {
  background-color: #fff;
  min-width: 310px;
  height: max-content;
  box-shadow: 2px 2px 2px 1px #dedbdb;
}

.sub-menu {
  &-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover {
    cursor: pointer;
  }
}

.left-sidebar{
  &-list{
    padding-top: 12px;
  }
  &-item {
    padding: 4px 16px;
    &:last-child {
      margin-bottom: 32px;
      
      a {
        color: #e64c2e;
      }
    }
  
    &:nth-last-child(2) {
      border-top: 1px solid #cecece;
      padding-top: 16px;
    }
    &:nth-last-child(3) {
      padding-bottom: 16px;
    }
  
    &:hover {
      cursor: pointer;
      color: #67aeca;
    }
  
    a:hover {
      color: #67aeca;
    }
    &.active {
      a {
        color: #67aeca;
      }
    }
  }
}
.sub-menu {
  padding-left: 16px;
  margin-bottom: 8px;
  &.active {
    a {
      color: #67aeca;
    }
  }
  &:first-child {
    margin-top: 8px;
  }
}
