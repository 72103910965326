.btn-submit {
  background-color: #67AECA;
  color: #fff;
  padding: 11px 30px;
}

.publish-btn{
    position: absolute;
    right: 5%;
    bottom: 10%;
}
