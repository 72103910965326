.empty-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #67AECA;
    font-size: 36px;
}

.img-wrapper {
    .user-img {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }

    .img-logo {
        display: block;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;
    }
}