.country {
    &-wrapper {
        width: 47%;
        .select-component{
            width: 100%;
        }
    }
    &-label{
        margin-bottom: 19px;
    }
}