.header {
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  z-index: 2;

  &-inner {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    width: 100%;
  }

  .drop-menu-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
  }
}


.options {
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    cursor: pointer;
  }

  @include breakpoint(md) {
    gap: 7px;
  }

  @include breakpoint(sm) {
    gap: 5px;
  }
}

header .select {
  background-color: transparent;

  &-option {
    display: flex;
    align-items: center;
    padding: 5px 11px;
    gap: 7px;

    &:hover {
      background-color: $blue-gray;
    }
  }

  &-content {
    top: 42px;
    left: 0;
  }

  .select-btn {
    gap: 5px;
    padding: 11px 5px;
  }

  .arrow-span {
    width: 15px;
    height: 15px;
    // background: center no-repeat url('../../../public/images/icons/arrows/arrow-down-small.svg');
  }
}

.select-option-img {
  display: block;
  width: 24px;
  height: 24px;
}

header .select.is-open {
  background-color: $light-blue;

  .arrow-span {
    // background: center no-repeat url('../../../public/images/icons/arrows/arrow-down--white.svg');
  }

  .user-name {
    color: #fff;
  }
}

.user {
  display: flex;
  align-items: center;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $blue;
    // background: center no-repeat url("../../../public/images/icons/user-icon.png");
    border-radius: 50%;

    &:hover {
      cursor: pointer;
    }
  }

  // &-name {
  //   font-size: 14px;
  //   color: $blue;
  // }

  // &-arrow {
  //   display: block;
  //   width: 24px;
  //   height: 24px;
  //   // background: center no-repeat url("../../../public/images/icons/arrow-right-small.svg");
  // }
}

.user {
  &-icon {
    background-color: #E2E2E2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 20px;
  }

  .select-option {
    a:not(.log-out) {
      display: flex;
      align-items: center;
    }

    .log-out {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  font-size: 20px;
  background-color: #E2E2E2;
  border-radius: 50%;
}