.navbar {
  flex: 2;

  @include breakpoint(lg) {
  }

  @include breakpoint(sm) {
    flex: 0;
    position: relative;
  }
}

.nav {
  display: flex;
  justify-content: space-evenly;
  padding-left: 10px;
  padding-right: 10px;
  align-items: baseline;
  width: 60%;

  @include breakpoint(md) {
    padding-left: 5px;
    padding-right: 5px;
  }
  @include breakpoint(md) {
    padding: 10px 5px;
  }
  .header &-link:not(.current) {
    &:hover {
      border-top: 4px solid $light-blue;
    }

    @include breakpoint(sm) {
      &:hover {
        border-top: none;
      }
    }
  }

  @include breakpoint(md) {
    justify-content: space-evenly;
    padding: 0 7px;
    gap: normal;
  }

  @include breakpoint(sm) {
    display: none;
    padding: 0;
  }

  ul {
    display: flex;
    justify-content: space-around;
    width: 90%;

    // gap: 70px;

    @include breakpoint(lg) {
      gap: 30px;
    }

    @include breakpoint(md) {
      gap: 10px;
    }

    @include breakpoint(sm) {
      display: block;
      width: 100%;
      text-align: center;
      // padding-top: 20px;
      padding-bottom: 10px;
    }
    li {
      @include breakpoint(sm) {
        display: block;
        padding: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: $blue-gray;
        }
      }
    }
  }
}

.burger-menu {
  display: none;

  @include breakpoint(sm) {
    display: inline-block;
  }
}

.open {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: -67px;
  top: 35px;
  width: 178px;
  padding-bottom: 20px;
  background-color: $light-grey;

  .nav-link {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #e0f4f8;
  }
}

// .active {
//   padding: 0 0 0 20px;
//   background: $light-grey;
// }

.current {
  padding-top: 8px;
  border-top: 4px solid $dark-blue;

  @include breakpoint(sm) {
    border-top: none;
    border-right: 4px solid $dark-blue;
  }
}

.sell-link {
  display: block;
  padding: 11px 10px 10px 10px;
  width: max-content;
  color: #fff;
  background: $light-blue;

  &:hover {
    background: rgba(103, 174, 202, 0.9);
  }
  @include breakpoint(sm) {
    margin: 0 auto;
  }

  // @include breakpoint(xlg){
  //   top: 2px;

  // }
  // @include breakpoint(lg){
  //   top: 0;
  //   left: calc(100% - 290px);
  // }
  // @include breakpoint(md){
  //   position: static;

  // }
  // @include breakpoint(sm){
  //   position: static;
  //   width: 100%;
  //   text-align: center;
  // }
}
