.reply-card {
    padding: 20px;
    position: relative;
    width: 50%;

    &-sender {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;

        &-info {
            margin-bottom: 20px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        &-info-text-wrapper{
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
    &-message{
        margin-bottom: 20px;
    }
    &-responce-label{
        margin-bottom: 20px;
    }
    .buttons-wrapper{
        gap: 15px;
        position: absolute;
        right: 2%;
        bottom: 10%;
    }
    .fa-regular{
        font-weight: 500;
    }
}

.reply-buttons-wrapper{
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
  }