.btn-danger {
  &--outline {
    background-color: #fff;
    color: #E64C2E;
    padding: 10px 30px;
    border: 1px solid #E64C2E;
    box-sizing: border-box;
  }

  &--filled {
    background-color: #E64C2E;
    color: #fff;
    padding: 10px 30px;
    border: 1px solid #fff;
    box-sizing: border-box;
  }
}