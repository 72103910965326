.hamburger-btn {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;

  border: 1px solid #333;

  &__line {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 22px;
    height: 2px;
    background-color: #333;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: .3s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #333;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      transition: .3s;
    }

    &::before {
      top: -7px;
    }

    &::after {
      top: 7px;
    }
  }

  &.is-active {

    .hamburger-btn__line {
      background-color: transparent;

      &::before {
        top: 0;
        transform: rotate(45deg);
      }

      &::after {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }
}