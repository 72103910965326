.logo {
    display: flex;
    align-items: center;
    gap: 17px;
    font-size: 28px;
    line-height: 28px;
    text-transform: capitalize;

    @include breakpoint(lg) {
        font-size: 20px;
        gap: 10px;
    }
    @include breakpoint(md){
        gap: 5px;
    }

    &-img {
        display: block;
        width: 26px;
        height: 26px;

        @include breakpoint(lg) {
            width: 20px;
            height: 20px;
        }
    }
}