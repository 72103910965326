.system-message {
    position: relative;
    z-index: 5;
    background-color: #fff;
    padding: 10px 20px;
    width: 320px;
    position: fixed;
    right: 9.5%;
    top: 8%;
    box-shadow: 2px 2px 2px 1px #dedbdb;

    &-title {
        margin-bottom: 7px;
        font-size: 20px;
    }

    &-text {
        font-size: 16px;
    }

    &-close-btn {
        position: absolute;
        right: 5%;
        top: 10%;
        color: #000;
        &:hover {
            cursor: pointer;
        }
    }
    &--success{
        color: #7DC974;
    }
    &--error{
        color: #D26161;
    }
}