.filters {
  &-wrapper {
    margin-bottom: 20px;

    .sub-title {
      font-size: 18px;
    }

    .subtitle-btn {
      width: 235px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .section-hiden {
      opacity: 0;
      visibility: hidden;
      height: 0;
    }

    .section-visible {
      opacity: 1;
      visibility: visible;
      height: auto;
    }

    .fa-solid {
      font-size: 12px;
    }
  }

  &-section {
    display: flex;
    align-items: center;
    row-gap: 10px;
    column-gap: 5%;
    flex-wrap: wrap;
  }

  &-checkbx-wrapper {
    width: 30%;
  }

}