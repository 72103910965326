.form {
  &__group {
    display: block;
    margin-bottom: 25px;
  }
  &-title{
    margin-bottom: 32px;
  }
}


.search-form {
  &-wrapper {
    opacity: 0;
    height: 0;
    visibility: hidden;
    transform: translateY(-15px);
    transition: opacity 1s ease, transform 1s ease;
  }

  &-wrapper.in {
    height: auto;
  }

  &-wrapper.in.visible {
    height: auto;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);

    .btns-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  &-inner {
    display: flex;
    column-gap: calc(8% / 3);
    row-gap: 40px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
  }

  .adv-list-form-field {
    width: 23%;

    &-label {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    width: 23%;
  }

  .fa-xmark {
    &:hover {
      cursor: pointer;
    }
  }
}

.applications-search-form {
  padding-bottom: 20px;
  
  &:not(.favorites) {
    border-bottom: 3px solid #CECECE;
    margin-bottom: 20px;
  }
}

.favorites-search-form {
  .search-form-inner {
    justify-content: space-between;
  }

  .adv-list-form-field {
    width: 31%;
  }

  .label {
    width: 31%;
  }
}