.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  gap: 20px;
  &-list {
    display: flex;
    align-items: center;
    gap: 10px;
    &-item {
      opacity: 0.3;
      font-size: 30px;
        &:hover{
            cursor: pointer;
        }
      &.current-link {
        opacity: 1;
      }
    }
  }
  .fa-arrow-left, .fa-arrow-right{
    font-size: 24px;
  } 
}
