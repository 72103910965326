
.loader-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(128, 128, 128, 0.2);
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 360px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loader-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    content: center;
    align-items: center;
    background-color: rgba(15, 15, 15, 0.5);
    border-radius: 5px;
    height: 200px;
    width: 200px;
    box-shadow: 1px 1px 1px 0px darkslategrey;
}

.loader {
    border: 1px solid white;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    width: 80px;
    height: 80px;
    animation-name: loading;
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.loader-text {
    margin-top: 10px;
    padding-top: 10px;
    color: lightgrey;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    animation-name: fading;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fading {

    0%,
    100% {
        opacity: 0.05;
    }

    50% {
        opacity: 0.95;
    }
}