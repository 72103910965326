.module {
    padding: 30px 0;
    margin-bottom: 20px;
    &:last-child:not(.photo-module){
        margin-bottom: 0;
        padding: 20px 0;
    }
    &:last-child.photo-module{
        margin-bottom: 0;
        padding: 0;
    }
    &:first-child{
        padding-top: 0;
        margin-bottom: 0;
    }
}
.flor-plan{
    padding-top: 20px;
}
.line{
    border-top: 3px solid #CECECE;
}
.adv-content {
    flex: 2;
    padding: 32px;
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px #dedbdb;
}
.adv-list-content{
    flex: 2;
    padding:0 0 32px 0;
    background-color: #fff;
    box-shadow: 2px 2px 2px 1px #dedbdb;
}

.control-panel-wrapper{
    padding: 32px;
}