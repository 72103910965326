.list-card {
    display: flex;
    align-items: center;
    border-top: 1px solid #a0a0a0;
    
    &-image {
      display: block;
      width: 315px;
      height: 250px;
      object-fit: cover;
    }
  
    &-id {
      color: #a0a0a0;
      margin-bottom: 15px;
    }
  
    &-type {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  
    &-location {
      font-size: 18px;
      color: #b1b1b1;
      margin-bottom: 10px;
    }
  
    &-general {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 10px;
      margin-bottom: 20px;
      color: #b1b1b1;
    }
  }

  