.contacts {
    &-add-btn {
        &-wrapper {
            margin-top: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.input-checkbox-wrapper {
    display: flex;
    position: relative;
    gap: 20px;
    margin-bottom: 30px;

    .label {
        width: 100%;
    }
}

.fa-plus {
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
    color: #CCCCCC;
    border: 1px solid #CCCCCC;

    &:hover {
        cursor: pointer;
    }
}