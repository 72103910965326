.legend {
    background-color: #fff;
    padding: 32px 16px;
    width: 300px;
    box-shadow: 2px 2px 2px 1px #dedbdb;
    &-title {
        margin-bottom: 20px;
    }

    &-card {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 20px;

        &-img {
            display: block;
            width: 22px;
            height: 22px;
        }

        &-text {
            font-size: 12px;
        }
    }
}